<template>
  <v-container v-if="!loading" fluid class="pa-0">
    <v-card class="ma-2 pa-2">
      <Time />
      <div 
        @click="fichar"
        style="margin:10px auto;
              width: 200px;
              height: 200px;
              border-radius: 50%;
              box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: x-large;
              font-weight: bold;"
        :class="trabajando !== null ? (trabajando === true ? 'errorBtn' : 'successBtn') : 'unknownBtn'">{{ trabajando !== null ? (trabajando === true ? 'SALIR' : 'ENTRAR') : 'ERROR' }}</div>
      <h5 class="text-center">Ubicación {{ ubi ? 'activada' : 'desactivada' }}</h5>
      <v-list>
        <h4 class="primary--text mb-2">FICHAJES</h4>
        <template v-for="[date,fichaje] in Object.entries(fichajes)" v-if="Object.keys(fichajes).length !== 0">
          <v-subheader style="height: auto !important;">{{ date }}</v-subheader>
          <v-divider class="mb-2"></v-divider>
          <template>
            <v-row v-for="f in fichaje" :key="f.idFichaje">
              <v-col class="align-center justify-center d-flex py-0" cols="2">
                <v-btn :disabled="!f.latitudEntrada || !f.longitudEntrada" icon :color="(!f.latitudEntrada || !f.longitudEntrada) ? '' : 'error'" @click="openLocation(f.latitudEntrada, f.longitudEntrada)"><v-icon>{{ (!f.latitudEntrada || !f.longitudEntrada) ? 'mdi-map-marker-off' : 'mdi-map-marker' }}</v-icon></v-btn>
              </v-col>
              <v-col class="align-center justify-center d-flex py-0" cols="4">
                <v-list-item-title v-text="f.entrada ? new Date(f.entrada).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''"></v-list-item-title>
              </v-col>
              <v-col class="align-center justify-center d-flex py-0" cols="2">
                <v-btn :disabled="!f.latitudSalida || !f.longitudSalida" icon :color="(!f.latitudSalida || !f.longitudSalida) ? '' : 'error'" @click="openLocation(f.latitudSalida, f.longitudSalida)"><v-icon>{{ (!f.latitudSalida || !f.longitudSalida) ? 'mdi-map-marker-off' : 'mdi-map-marker' }}</v-icon></v-btn>
              </v-col>
              <v-col class="align-center justify-center d-flex py-0" cols="4">
                <v-list-item-title v-text="f.salida ? new Date(f.salida).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''"></v-list-item-title>
              </v-col>
            </v-row>
          </template>
        </template>
        <template v-else>
          <v-list-item>
            SIN DATOS
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Time: () => import("../components/Time.vue"),
  },
  data() {
    return {
      loading: false,
      trabajando: null,
      ubi: false,
      fichajes:{}
    };
  },
  async mounted(){
    this.loading = true
    await Promise.all([
      this.checkLocation(),
      this.checkStatus(),
      this.getFichajes(),
    ])
    this.loading = false
  },
  methods: {
    openLocation(lat,long){
      window.open(`https://maps.apple.com/maps?q=${lat},${long}`, '_blank').focus();
    },
    checkLocation(){
      if ("geolocation" in navigator) {
        navigator.permissions.query({name: 'geolocation'}).then((permissionStatus) => {
          if (permissionStatus.state === 'granted') {
            this.ubi = true
          } else if (permissionStatus.state === 'prompt') {
            navigator.geolocation.getCurrentPosition((position) => {
              this.ubi = true
            }, (error) => {
              this.ubi = false
            });
          } else if (permissionStatus.state === 'denied') {
            this.ubi = false
          }
        });
      } else {
        this.ubi = false
      }
    },
    async fichar(){
      if (this.trabajando === null) return
      this.loading = true
      var pos = null
      if (this.ubi){
        navigator.geolocation.getCurrentPosition((position) => {
          pos = position;
        });
      }
      try {
        pos = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      } catch (error) {
        console.error('Geolocation error:', error);
      }
      const locationData = pos ? {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      } : {
        latitude: null,
        longitude: null
      };
      try{
        const { data } = await axios({
          method: "post",
          url:"/fichaje_equipo",
          data: locationData
        })
        this.$set(this.$data, "trabajando", data)
      }
      catch (e){
        this.$root.$emit("snack", "No se ha podido fichar correctamente")
      }
      finally{
        this.getFichajes()
        this.loading = false
      }
    },
    async checkStatus(){
      try{
        const { data } = await axios({
          url: "/fichaje_equipo/status"
        })
        this.$set(this.$data, "trabajando", data)
      }
      catch(e){
        this.$root.$emit("snack", "Error al recuperar el estado")
        this.$set(this.$data, "trabajando", null)
      }
    },
    async getFichajes(){
      try{
        const { data } = await axios({
          url: "/fichaje_equipo"
        })

        this.fichajes = data.reduce((acc, entry) => {
          const dateKey = entry.entrada.split('T')[0];
          if (!acc[dateKey]) {
            acc[dateKey] = [];
          }
          acc[dateKey].push(entry);
          return acc;
        }, {});
        
        const sortedEntries = Object.entries(this.fichajes).sort((a, b) => new Date(b[0]) - new Date(a[0]));
        this.$set(this.$data, "fichajes", Object.fromEntries(sortedEntries));
      }
      catch(e){
        console.log(e);
        this.$root.$emit("snack", "Error al recuperar los fichajes")
      }
    }
  },
};
</script>

<style>
.calDay{
  height: 40px !important;
  width: 40px !important;
  transition: background-color 0.1s ease;
}
.dark-badge .v-badge__badge .v-icon {
  color: black;
}
.light-badge .v-badge__badge .v-icon {
  color: white;
}
.errorBtn{
  background-color: var(--v-error-base);
}
.successBtn{
  background-color: var(--v-success-base);
}
.unknownBtn{
  background-color: black;
  color:white;
}
</style>